.game-container.lottery {
    gap: 30px;
}

.lottery h3 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 0 0 16px;
}

.lottery .legend-board,
.lottery .lottery-board,
.lottery .score-board,
.lottery .bet-board {
    background: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.2), 0 0 3px 0 rgba(255,255,255,0.2) inset;
}

.lottery-board {
    width: 400px;
    height: 400px;
    padding: 10px;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.lottery-card {
    box-sizing: border-box;
    width: 120px;
    height: 120px;
    border-radius: 5px;
    position: relative;
    /* background: url(../img/sber-logo.svg) center center no-repeat rgb(141, 237, 141); */
    perspective: 400px;
}

.lottery-card-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

}

.lottery-card-cover {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    z-index: 10;

    background: rgba(19, 197, 230, 0.85);
    background: white,
        linear-gradient(-120deg, #008EE4 2.61%, rgba(19, 197, 230, 1) 17.65%, #8BD611 86.46%, #B5C510 100%);
    background:
        radial-gradient(112.09% 577.22% at 98.54% -19.32%, #008EE4 2.61%, rgba(19, 197, 230, 1) 17.65%, #8BD611 86.46%, #B5C510 100%);

    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2) inset;
}

.lottery-card.rotate .lottery-card-cover {
    transform: rotateY(90deg);
	animation: rotateAnimation 1s linear 0.25;
}

@keyframes rotateAnimation {
	from {transform: rotateY(45deg);}
	to {transform: rotateY(225deg);}
}


.lottery .game-controls,
.lottery .game-info {
    align-self: top;
    height: 400px;
}

.lottery .legend-board {
    width: 200px;
    padding: 20px 22px;
}

.lottery .legend-board ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.lottery .legend-board li {
    display: block;
    margin: 0;
    
}

.lottery .legend-board li + li {
    margin-top: 20px;    
}

.lottery .legend-board .tile {
    display: inline-block;
    vertical-align: text-bottom;
    width: 18px;
    height: 18px;
    background: #9d9d9d;
    background: linear-gradient(135deg, #e2e2e2 0%, #bbbbbb 10%, #8a8a8a 90%, #424242 100%);
    border-radius: 50%;
    box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2), 0 0 2px 1px rgba(128,128,128,0.15) inset;
    margin: 0 24px 0 0;
}

.lottery .score-board {
    padding: 20px 30px 20px 22px;
    font-weight: 600;
    margin-bottom: 20px;
}

.lottery .score-board div {
    margin-top: 12px;
    font-size: 32px;
    /* text-align: right; */
    white-space: nowrap;
}

.lottery .bet-board {
    padding: 20px 22px;
    font-weight: 600;
    margin-bottom: 30px;

}

.lottery .bet-board .value {
    font-size: 24px;
    line-height: 40px;
    white-space: nowrap;
    position: relative;
}

.lottery .bet-board .change-bet {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 40px;
}

.lottery .bet-board .change-bet span {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 3px;
    cursor: pointer;
}

.lottery:not(.mode-play) .bet-board .change-bet span:hover {
    background: rgb(128, 128, 128, 0.2);
}

.lottery .bet-board .change-bet svg {
    stroke: #212529;
    display: inline-block;
    transform-origin: center center;
}

.lottery.mode-play .bet-board .change-bet span {
    opacity: 0.2;
    cursor: not-allowed;
}

.lottery.mode-gameover .bet-board .change-bet {
    display: none;
}

.lottery .bet-board .change-bet .increase svg {
    transform: rotate(90deg);
}

.lottery .bet-board .change-bet .reduce svg {
    transform: rotate(-90deg);
}

.lottery .control-button {
    text-align: center;
}

.lottery .control-button button {
    display: inline-block;
    min-width: 110px;
    padding: 18px 24px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    border: none;
    letter-spacing: 0.5px;
    min-width: 120px;

    background: rgba(33, 160, 56, 1);
    color: #fff;
    border-radius: 16px;
    cursor: pointer;
}

.lottery.mode-gameover .control-button button {
    opacity: 0.4;
    cursor: default;
}

@keyframes blinker {
    from { opacity: 1.0; }
    to { opacity: 0.0; }
}  

.lottery .control-button button.flashing {
    animation: blinker 0.15s cubic-bezier(.5, 0, 1, 1) 4;
}

.lottery-card-content::after {
    content: '';
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 30px;
    /* box-shadow: 5px 5px 5px 0 rgba(0,0,0,0.3), 0 0 1px 4px rgba(200,200,200,0.15) inset; */
    box-shadow: 4px 4px 4px 0 rgba(0,0,0,0.2), 0 0 2px 1px rgba(128,128,128,0.15) inset;
    cursor: pointer;
}

.lottery-card.purple .lottery-card-content::after {
    background: linear-gradient(135deg, #f233e8 0%, #DB17D1 10%, #800a7a 90%, #4f074b 100%);
}

.lottery-card.green .lottery-card-content::after {
    background: linear-gradient(135deg, #01df01 0%, #00ba00 10%, #026502 90%, #025e02 100%);
}

.lottery-card.red .lottery-card-content::after {
    background: linear-gradient(135deg, #ff6a6a 0%, #ff5f5f 10%, #920606 90%, #4a0202 100%);
}

.lottery-card.yellow .lottery-card-content::after {
    background: linear-gradient(135deg, #ffff57 0%, #f7f72c 10%, #c8c80f 90%, #5b5b03 100%);
}

.lottery-card.blue .lottery-card-content::after {
    background: linear-gradient(135deg, #486fff 0%, #2252ff 10%, #0931bf 90%, #0a268b 100%);
}

.lottery-card.orange .lottery-card-content::after {
    background: linear-gradient(135deg, #ffbf7b 0%, #ffa545 10%, #c76f12 90%, #a65b0b 100%);
}

.lottery-card.cyan .lottery-card-content::after {
    background: linear-gradient(135deg, #31eff2 0%, #17d6d9 10%, #0a7e80 90%, #074f50 100%);
}

.lottery-card.blank .lottery-card-content {
    display: none;
}
