.wheel-of-fortune .spin-button {
    display: inline-block;
    min-width: 110px;
    padding: 18px 24px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    border: none;
    letter-spacing: 0.5px;
    min-width: 150px;

    background: rgba(33, 160, 56, 1);
    color: #fff;
    border-radius: 16px;
    cursor: pointer;
}