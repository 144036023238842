@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-Regular.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-SemiBold.otf');
  font-weight: 600;
}
@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/SuisseIntl-Bold.otf');
  font-weight: 700;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(33, 37, 41, 1);
    background: rgba(233, 231, 241, 0.9);
}

body,
button {
  font-family: 'Suisse Intl', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
}

.hidden {
    visibility: hidden;
}

.mainmenu {
    padding: 20px;
    background: #fff;
    user-select: none;
    position: relative;
    z-index: 100;
}

.mainmenu a {
    text-decoration: none;
    color: #2c3341;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.mainmenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mainmenu li {
    display: inline-block;
    margin: 0 30px;
}

.game-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 560px;

  user-select: none;
}

.game-controls {
  box-sizing: border-box;
  width: 200px;
}

h1 {
  font-size: 60px;
  line-height: 1.2em;
  letter-spacing: 3px;
}