@keyframes bounceleft {
    from { transform: translate(0, 0); }
    50% { transform: translate(-50px, 0); }
    60% { transform: translate(-50px, 0); }
    to { transform: translate(0, 0); }
}

@keyframes bounceright {
    from { transform: translate(0, 0); }
    50% { transform: translate(50px, 0); }
    60% { transform: translate(50px, 0); }
    to { transform: translate(0, 0); }
}

@keyframes bouncetop {
    from { transform: translate(0, 0); }
    50% { transform: translate(0, -50px); }
    60% { transform: translate(0, -50px); }
    to { transform: translate(0, 0); }
}

@keyframes bouncedown {
    from { transform: translate(0, 0); }
    50% { transform: translate(0, 50px); }
    60% { transform: translate(0, 50px); }
    to { transform: translate(0, 0); }
}

@keyframes falldown {
    from { transform: translate(0, -50px);  }
    to { transform: translate(0, 0); }
}

.move-down-anim {
    animation: falldown 0.2s 1;
}


.swap-left-first,
.swap-right-second {
    animation: bounceleft 0.6s 1;
}

.swap-left-second,
.swap-right-first {
    animation: bounceright 0.6s 1;
}

.swap-top-first,
.swap-bottom-second {
    animation: bouncetop 0.6s 1;
}

.swap-top-second,
.swap-bottom-first {
    animation: bouncedown 0.6s 1;
}


.tiles {
    width: 400px;
    height: 400px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.tiles .tile {
    width: 48px;
    height: 48px;
    border-radius: 7px;
    margin: 1px;
}

.tiles .tile::after {
    content: '';
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: 6px;
    /* box-shadow: 5px 5px 5px 0 rgba(0,0,0,0.3), 0 0 1px 4px rgba(200,200,200,0.15) inset; */
    box-shadow: 4px 4px 4px 0 rgba(0,0,0,0.2), 0 0 2px 1px rgba(128,128,128,0.15) inset;
    cursor: pointer;
}

.tiles .tile.purple::after {
    background: linear-gradient(135deg, #f233e8 0%, #DB17D1 10%, #800a7a 90%, #4f074b 100%);
}

.tiles .tile.green::after {
    background: linear-gradient(135deg, #01df01 0%, #00ba00 10%, #026502 90%, #025e02 100%);
}

.tiles .tile.red::after {
    background: linear-gradient(135deg, #ff6a6a 0%, #ff5f5f 10%, #920606 90%, #4a0202 100%);
}

.tiles .tile.yellow::after {
    background: linear-gradient(135deg, #ffff57 0%, #f7f72c 10%, #c8c80f 90%, #5b5b03 100%);
}

.tiles .tile.blue::after {
    background: linear-gradient(135deg, #486fff 0%, #2252ff 10%, #0931bf 90%, #0a268b 100%);
}

.tiles .tile.orange::after {
    background: linear-gradient(135deg, #ffbf7b 0%, #ffa545 10%, #c76f12 90%, #a65b0b 100%);
}

.tiles .tile.cyan::after {
    background: linear-gradient(135deg, #31eff2 0%, #17d6d9 10%, #0a7e80 90%, #074f50 100%);
}

.tiles .tile.blank::after {
    visibility: hidden;
}

.tiles .tile.selected {
  transition: background-color 0.2s ease;
  box-shadow: 0 0 3px 0 rgba(30, 30, 30, 0.1) inset;
  background-color: rgba(190, 190, 190, 0.7);
}

.match-three-app .game-controls {
    padding-top: 10px;
}

.match-three-app .score-board {
    background: #fff;
    border-radius: 5px;
    padding: 20px 30px 20px 22px;
    font-weight: 600;
    box-sizing: border-box;
    box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.2), 0 0 3px 0 rgba(255,255,255,0.2) inset;

}

.match-three-app .score-board div + div {
    margin-top: 12px;
    font-size: 32px;
    text-align: right;
}